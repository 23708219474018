import { mainnet, goerli } from 'wagmi/chains'
import { Chain } from 'wagmi'


const bscExplorer = { name: 'BscScan', url: 'https://bscscan.com' }
const shibExplorer = { name: 'ShibariumScan', url: 'https://www.shibariumscan.io' }
const dcExplorer = { name: 'DogeChain Explorer', url: 'https://explorer.dogechain.dog' }

export const bsc: Chain = {
  id: 56,
  name: 'BNB Smart Chain',
  network: 'bsc',
  rpcUrls: {
    default: {
      http: ['https://bsc-dataseed1.binance.org'],
    },
    public: {
      http: ['https://bsc-dataseed1.binance.org'],
    }
  },
  blockExplorers: {
    default: bscExplorer,
    etherscan: bscExplorer,
  },
  nativeCurrency: {
    name: 'Binance Chain Native Token',
    symbol: 'BNB',
    decimals: 18,
  },
  contracts: {
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
      blockCreated: 15921452,
    },
  }
}

export const bscTest: Chain = {
  id: 2024115,
  name: 'DC ZKEVM Testnet',
  network: 'bsc-testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Dogechain Testnet Native Token',
    symbol: 'ETH',
  },
  rpcUrls: {
    default: {
      http: ['https://rpc.testnet-cdk.dogechain.dog'],
    },
    public: {
      http: ['https://rpc.testnet-cdk.dogechain.dog'],
    }
  },
  blockExplorers: {
    default: { name: 'Dogechain Testnet Explorer', url: 'https://explorer.testnet-cdk.dogechain.dog' },
  },
  contracts: {
    multicall3: {
      address: '0x60cd6352Ba5065E0A26BC5Fb0B6B6212DF8Ad9d9',
      blockCreated: 893,
    },
  },
  testnet: true,
}

export const dogechain: Chain = {
  id: 2000,
  name: 'DogeChain',
  network: 'dogechain',
  rpcUrls: {
    default: {
      http: ['https://rpc.dogechain.dog'],
    },
    public: {
      http: ['https://rpc.dogechain.dog'],
    }
  },
  blockExplorers: {
    default: dcExplorer,
    etherscan: dcExplorer,
  },
  nativeCurrency: {
    name: 'Wrapped DOGE',
    symbol: 'DOGE',
    decimals: 18,
  },
  contracts: {
    multicall3: {
      address: '0x68a8609a60a008EFA633dfdec592c03B030cC508',
      blockCreated: 1419910,
    },
  },
}

export const shibarium: Chain = {
  id: 109,
  name: 'Shibarium',
  network: 'shibarium',
  rpcUrls: {
    default: {
      http: ['https://www.shibrpc.com'],
    },
    public: {
      http: ['https://www.shibrpc.com'],
    }
  },
  blockExplorers: {
    default: shibExplorer,
    etherscan: shibExplorer,
  },
  nativeCurrency: {
    name: 'Wrapped BONE',
    symbol: 'BONE',
    decimals: 18,
  },
  contracts: {
    multicall3: {
      address: '0xB585B461950c4A684aDB06f8F03eB1c3410ED2c5',
      blockCreated: 791891,
    },
  },
}

export { mainnet, goerli }
