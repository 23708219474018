import { ContextApi } from '@pancakeswap/localization'
import {
  DropdownMenuItemType,
  EarnFillIcon,
  EarnIcon,
  MenuItemsType,
  MoreIcon,
  PoolFillIcon,
  PoolIcon,
  StakingFillIcon,
  StakingIcon,
  SwapFillIcon,
  SwapIcon,
  TicketFillIcon,
  TicketIcon,
} from '@pancakeswap/uikit'
import { DropdownMenuItems } from '@pancakeswap/uikit/src/components/DropdownMenu/types'
import { SUPPORT_ONLY_SHIB } from 'config/constants/supportChains'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
    [
      {
        label: t('Exchange'),
        icon: SwapFillIcon,
        fillIcon: SwapFillIcon,
        href: '/swap',
        showItemsOnMobile: false,
        items: [].map((item) => addMenuItemSupported(item, chainId)),
      },
      {
        label: t('Liquidity'),
        icon: PoolFillIcon,
        fillIcon: PoolFillIcon,
        href: '/liquidity',
        showItemsOnMobile: false,
        items: [].map((item) => addMenuItemSupported(item, chainId)),
      },
      {
        label: t('DINUwin'),
        icon: TicketFillIcon,
        fillIcon: TicketFillIcon,
        href: 'https://win.dogeinu.dog/',
        showItemsOnMobile: false,
        items: [].map((item) => addMenuItemSupported(item, chainId)),
      },
      // {
      //   label: t('Win'),
      //   href: '/prediction',
      //   icon: TrophyIcon,
      //   fillIcon: TrophyFillIcon,
      //   supportChainIds: SUPPORT_ONLY_BSC,
      //   items: [
      //     {
      //       label: t('Trading Competition'),
      //       href: '/competition',
      //       image: '/images/decorations/tc.png',
      //       hideSubNav: true,
      //     },
      //     {
      //       label: t('Prediction (BETA)'),
      //       href: '/prediction',
      //       image: '/images/decorations/prediction.png',
      //     },
      //     {
      //       label: t('Lottery'),
      //       href: '/lottery',
      //       image: '/images/decorations/lottery.png',
      //     },
      //     {
      //       label: t('Pottery (BETA)'),
      //       href: '/pottery',
      //       image: '/images/decorations/lottery.png',
      //     },
      //   ],
      // },
      // {
      //   label: t('NFT'),
      //   href: `${nftsBaseUrl}`,
      //   icon: NftIcon,
      //   fillIcon: NftFillIcon,
      //   supportChainIds: SUPPORT_ONLY_BSC,
      //   image: '/images/decorations/nft.png',
      //   items: [
      //     {
      //       label: t('Overview'),
      //       href: `${nftsBaseUrl}`,
      //     },
      //     {
      //       label: t('Collections'),
      //       href: `${nftsBaseUrl}/collections`,
      //     },
      //     {
      //       label: t('Activity'),
      //       href: `${nftsBaseUrl}/activity`,
      //     },
      //   ],
      // },
      {
        label: '',
        // href: '/info',
        icon: MoreIcon,
        hideSubNav: true,
        items: [
          // {
          //   label: t('Info'),
          //   href: '/info',
          //   supportChainIds: SUPPORT_ONLY_SHIB,
          // },
          // {
          //   label: t('IFO'),
          //   href: '/ifo',
          //   supportChainIds: SUPPORT_ONLY_BSC,
          //   image: '/images/ifos/ifo-bunny.png',
          // },
          // {
          //   label: t('Voting'),
          //   href: '/voting',
          //   supportChainIds: SUPPORT_ONLY_BSC,
          //   image: '/images/voting/voting-bunny.png',
          // },
          // {
          //   type: DropdownMenuItemType.DIVIDER,
          // },
          // {
          //   label: t('Leaderboard'),
          //   href: '/teams',
          //   supportChainIds: SUPPORT_ONLY_BSC,
          //   image: '/images/decorations/leaderboard.png',
          // },
          // {
          //   type: DropdownMenuItemType.DIVIDER,
          // },
          {
            label: t('DINU Homepage'),
            href: 'https://dogeinu.dog//',
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            type: DropdownMenuItemType.DIVIDER,
          },
          {
            label: t('Charts'),
            href: 'https://dexscreener.com/dogechain',
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: 'Bridge',
            href: 'https://dive.dogechain.dog/bridge',
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: t('Audit'),
            href: 'https://github.com/TechRate/Smart-Contract-Audits/blob/main/September_2022/Doge%20Inu.pdf',
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            type: DropdownMenuItemType.DIVIDER,
          },
          {
            label: t('Switch to ChewySwap'),
            href: 'https://github.com/TechRate/Smart-Contract-Audits/blob/main/September_2022/Doge%20Inu.pdf',
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
        ].map((item) => addMenuItemSupported(item, chainId)),
      },
    ].map((item) => addMenuItemSupported(item, chainId))

export default config
